
/**
 * @roxi/routify 2.6.1
 * File generated Thu Feb 29 2024 13:14:37 GMT+0700 (Indochina Time)
 */

export const __version = "2.6.1"
export const __timestamp = "2024-02-29T06:14:37.265Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/examples/index",
          "id": "_examples_index",
          "component": () => import('../src/pages/examples/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": true,
      "path": "/examples",
      "id": "_examples__reset",
      "component": () => import('../src/pages/examples/_reset.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/login/index",
          "id": "_login_index",
          "component": () => import('../src/pages/login/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": true,
      "path": "/login",
      "id": "_login__reset",
      "component": () => import('../src/pages/login/_reset.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/maths-hack-m1-sample/index",
          "id": "_mathsHackM1Sample_index",
          "component": () => import('../src/pages/maths-hack-m1-sample/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": true,
      "path": "/maths-hack-m1-sample",
      "id": "_mathsHackM1Sample__reset",
      "component": () => import('../src/pages/maths-hack-m1-sample/_reset.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/triam-hack-sci-examples/index",
          "id": "_triamHackSciExamples_index",
          "component": () => import('../src/pages/triam-hack-sci-examples/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": true,
      "path": "/triam-hack-sci-examples",
      "id": "_triamHackSciExamples__reset",
      "component": () => import('../src/pages/triam-hack-sci-examples/_reset.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

